import request from '../request'

//角色框列表
export const ShopBoxIndexRequest = p => {
    return request({
        method:'GET',
        url:'ShopBox/index',
        params: p
    })
}

//角色框序列
export const ShopBoxListRequest = () => {
    return request({
        method:'GET',
        url:'ShopBox/list'
    })
}

//添加角色框
export const ShopBoxAddRequest = d => {
    return request({
        method:'POST',
        url:'ShopBox/add',
        data: d
    })
}

//删除角色框
export const ShopBoxDelRequest = id => {
    return request({
        method:'DELETE',
        url:'ShopBox/del',
        data: {
            id: id
        }
    })
}

//修改角色框
export const ShopBoxEditRequest = data => {
    return request({
        method:'PUT',
        url:'ShopBox/edit',
        data
    })
}

//获取角色框详情
export const ShopBoxDetailsRequest = id => {
    return request({
        method:'GET',
        url:'ShopBox/details',
        params: {
            id
        }
    })
}