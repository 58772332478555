import request from '../request'

//皮肤列表
export const ShopSkinIndexRequest = p => {
    return request({
        method:'GET',
        url:'ShopSkin/index',
        params: p
    })
}

//皮肤序列
export const ShopSkinListRequest = () => {
    return request({
        method:'GET',
        url:'ShopSkin/list'
    })
}

//添加皮肤
export const ShopSkinAddRequest = d => {
    return request({
        method:'POST',
        url:'ShopSkin/add',
        data: d
    })
}

//删除皮肤
export const ShopSkinDelRequest = id => {
    return request({
        method:'DELETE',
        url:'ShopSkin/del',
        data: {
            id: id
        }
    })
}

//修改皮肤
export const ShopSkinEditRequest = data => {
    return request({
        method:'PUT',
        url:'ShopSkin/edit',
        data
    })
}

//获取皮肤详情
export const ShopSkinDetailsRequest = id => {
    return request({
        method:'GET',
        url:'ShopSkin/details',
        params: {
            id
        }
    })
}